import { GetServerSideProps } from 'next';
import { PageRendererComponent, prePageCall } from '@marriott/mi-headless-utils';
import { mockModel } from '../mocks/mockModel';

function pageRenderer(props) {
  return <PageRendererComponent {...props} />;
}
//Any product specific page data apart from common data like session, datalayer
//can be returned from this function and the same is passed as props to the page Component
const brandsSpecificData = async () => {
  return { operationSignatures: [] };
};

//Make pre page calls like session, datalayer, fetch model.json, producSpecifc data etc
//at server side and pass the data is passed as props to page component.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getServerSideProps: GetServerSideProps<any> = prePageCall(brandsSpecificData, mockModel);
export default pageRenderer;
